import classNames from 'classnames';
import ReactGA from 'react-ga4';
import { useHistory } from 'react-router-dom';

import { Caseload, Homeless } from 'types';

import ReferralTag from 'components/_shared/ReferralTag';

import { capitalizeWord } from 'util/index';

import { ButtonBase, CardMedia, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '16px',
            cursor: 'pointer',
            height: '100%'
        },
        cardContainer: {
            backgroundColor: 'rgb(137,157,193)',
            borderRadius: '16px',
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            '& img': {
                height: '100%',
                objectFit: 'cover',
                objectPosition: '50% 30%',
                width: '100%'
            }
        },
        exitedPill: {
            position: 'absolute',
            bottom: '12px',
            right: '12px',
            backgroundColor: '#FEF3E7',
            borderRadius: '20px',
            color: '#7A4406',
            fontSize: '12px',
            fontWeight: 800,
            lineHeight: '14px',
            padding: '4px 8px'
        },
        holderName: {
            bottom: '12px',
            left: '16px',
            color: '#FFFFFF',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '24px',
            position: 'absolute',
            wordBreak: 'break-word'
        },
        nameContainer: {
            position: 'relative',
            bottom: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        overlay: {
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.4)',
            borderRadius: '16px',
            height: '100%',
            width: '100%'
        },
        overlayDark: {
            backgroundColor: 'rgba(0,0,0,0.7)'
        }
    })
);

type HolderListItemProps = {
    caseload: Caseload;
    holder: Homeless;
    setReferCaseload: (value: Caseload) => void;
    setReferHolder: (value: Homeless) => void;
    setReferPopup: (value: boolean) => void;
};

const HoldersListItem: React.FC<HolderListItemProps> = ({
    caseload,
    holder,
    setReferCaseload,
    setReferHolder,
    setReferPopup
}) => {
    const classes = useStyles();
    const history = useHistory();

    const { status = 'active', is_accepted } = caseload || {};
    const { mobile_photo, profile_stage } = holder;

    const overlayClass = classNames(classes.overlay, {
        [classes.overlayDark]:
            status === 'pending' ||
            profile_stage === 'PENDING' ||
            profile_stage === 'MEMBERSHIP_CONFIRMED'
    });

    const handleClick = () => {
        ReactGA.event({
            category: 'Holder',
            action: 'View Holder',
            label: holder.id.toString()
        });

        if (status === 'pending') {
            setReferCaseload(caseload);
            setReferHolder(holder);
            setReferPopup(true);
        } else {
            history.push(`/holders/${holder.id}`);
        }
    };

    const renderReferralTagText = () => {
        if (typeof is_accepted !== 'undefined') {
            return is_accepted;
        }

        if (profile_stage === 'MEMBERSHIP_CONFIRMED') {
            return 'membership confirmed';
        }

        if (profile_stage === 'PENDING') {
            return 'pending';
        }

        return 'referred to you';
    };

    return (
        <ButtonBase
            className={classes.content}
            component="div"
            onClick={handleClick}
        >
            <div className={classes.cardContainer}>
                {(status === 'pending' ||
                    (status === 'active' && is_accepted) ||
                    (status === 'cancelled' && is_accepted) ||
                    profile_stage === 'MEMBERSHIP_CONFIRMED' ||
                    profile_stage === 'PENDING') && (
                    <ReferralTag text={renderReferralTagText()} />
                )}
                <span className={overlayClass} />
                <CardMedia
                    component="img"
                    image={mobile_photo}
                    loading="lazy"
                />
                <div className={classes.nameContainer}>
                    <Typography className={classes.holderName}>
                        {capitalizeWord(holder.name)}
                    </Typography>
                    {profile_stage === 'EXITED' ? (
                        <span className={classes.exitedPill}>Unenrolled</span>
                    ) : null}
                </div>
            </div>
        </ButtonBase>
    );
};

export default HoldersListItem;
