import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateHomelessInfo } from '../api/homeless';
import { updateHomelessInfoField } from 'redux/actions/homeless';
import { updateHomelessesMemberInfo } from 'redux/actions/homelesses';

import {
    catchUpItemFailure,
    catchUpItemSubmit,
    catchUpItemSuccess,
    completeItem
} from 'redux/actions/catch_up_items';

const useUpdateHomelessInfo = () => {
    const dispatch = useDispatch();

    const [error, setError] = useState<null | string>(null);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const updateHomelessInfoRequest = useCallback(
        (memberId: number, data: FormData, catchUpID?: number) => {
            setIsLoading(true);
            setIsError(false);

            if (catchUpID) {
                dispatch(catchUpItemSubmit(catchUpID));
            }

            updateHomelessInfo(memberId, data)
                .then((res: Response) => {
                    if (res.status === 400 || res.status === 422) {
                        return res.json().then((errorData: any) => {
                            const { attribute, description } =
                                errorData.errors[0];

                            setIsSuccess(false);
                            setIsError(true);
                            setError(
                                `${
                                    attribute.toUpperCase() || ''
                                }: ${description}`
                            );

                            if (catchUpID) {
                                dispatch(catchUpItemFailure(catchUpID));
                            }
                        });
                    } else {
                        setIsSuccess(true);
                        setIsError(false);

                        data.forEach((value, key) => {
                            const field = key.match(/samaritan_member\[(.*)\]/);

                            if (field && typeof value === 'string') {
                                dispatch(
                                    updateHomelessInfoField(field[1], value)
                                );
                            }

                            if (
                                field &&
                                typeof field[1] === 'string' &&
                                field[1] === 'profile_stage'
                            ) {
                                dispatch(
                                    updateHomelessesMemberInfo(
                                        memberId,
                                        field[1],
                                        'MEMBERSHIP_CONFIRMED'
                                    )
                                );
                            }
                        });

                        if (catchUpID) {
                            dispatch(catchUpItemSuccess(catchUpID));

                            setTimeout(() => {
                                dispatch(completeItem(catchUpID));
                            }, 2500);
                        }
                    }
                })
                .catch((err: any) => {
                    setIsSuccess(false);
                    setIsError(true);
                    setError(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [dispatch]
    );

    const reset = useCallback(() => {
        setIsSuccess(false);
        setIsError(false);
        setIsLoading(false);
        setError(null);
    }, []);

    return {
        error,
        isError,
        isLoading,
        isSuccess,
        reset,
        updateHomelessInfoRequest
    };
};

export default useUpdateHomelessInfo;
