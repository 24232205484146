import { cloneElement, useState } from 'react';

import { DebitCard } from 'types';

import LoadingCircle from '../LoadingCircle';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            borderRadius: '36px',
            color: '#FFFFFF',
            fontSize: '24px',
            fontWeight: 800,
            lineHeight: '22px',
            padding: '10px 20px',
            textTransform: 'none',
            width: '100%'
        },
        btnContainer: {
            padding: '20px 0'
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            padding: '30px'
        },
        iconContainer: {
            position: 'absolute',
            left: '50%',
            top: '-40px',
            transform: 'translateX(-50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#FFFFFF',
            borderRadius: '50%',
            fontSize: '32px',
            height: '72px',
            width: '72px'
        },
        modal: {
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            paddingLeft: '150px',
            width: '100%',
            '@media (max-width: 962px)': {
                paddingLeft: '0'
            }
        },
        modalContent: {
            position: 'relative',
            background: '#FFFFFF',
            borderRadius: '16px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            margin: '64px auto',
            maxWidth: '660px',
            overflow: 'auto',
            width: '80%'
        },
        overlayContainer: {
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            height: '100%',
            paddingLeft: '150px',
            width: 'calc(100% - 150px)',
            '@media (max-width: 962px)': {
                paddingLeft: '0',
                width: '100%'
            }
        },
        photo: {
            borderRadius: '50%',
            height: '100px',
            width: '100px'
        },
        photoContainer: {
            position: 'absolute',
            left: 'calc(50% - 50px)',
            top: '-60px',
            borderRadius: '50%',
            height: '100px',
            width: '100px',
            '& img': {
                borderRadius: '50%',
                height: '100%',
                objectFit: 'cover',
                width: '100%'
            }
        },
        successContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            color: '#1B9757',
            fontSize: '24px',
            fontWeight: 700,
            '& i': {
                fontSize: '40px'
            }
        },
        title: {
            color: '#FFFFFF',
            fontSize: '24px',
            fontWeight: 800,
            lineHeight: '22px'
        },
        titleContainer: {
            padding: '30px 0'
        }
    })
);

type PopUpProps = {
    backgroundColor?: string;
    children: React.ReactNode;
    customClasses?: {
        [key: string]: {
            [key: string]: string;
        };
    };
    debitInfo?: DebitCard;
    icon?: string;
    modalClose?: boolean;
    modalTitle?: string;
    onClose: () => void;
    open: boolean;
    photo?: string;
    nonAutoTransfer?: boolean;
    title: string;
};

const renderOverlayMessage = (title: string, debitInfo?: DebitCard) => {
    switch (title) {
        case 'close':
            return `${
                debitInfo?.card_type?.toUpperCase() || 'Debit Card'
            } Ending in ${debitInfo?.card_last4 || `'-'`} Closed.`;
        case 'confirm':
            return 'Membership Confirmed 🎉';
        default:
            return 'Success!';
    }
};

const renderStyles = (title: string) => {
    switch (title) {
        case 'close':
            return {
                styles: {
                    background:
                        'linear-gradient(to bottom, #FEE7EC 45%, #FBFBFF 0%)',
                    overflow: 'visible'
                },
                iconName: 'fa-trash-alt',
                iconStyle: {
                    backgroundColor: '#FEE7EC',
                    border: '3px solid #A80A2D',
                    color: '#A80A2D'
                }
            };
        case 'confirm':
            return {
                styles: {
                    background:
                        'linear-gradient(to bottom, #EBEBFF 45%, #FBFBFF 0%)',
                    overflow: 'visible',
                    borderRadius: '16px'
                }
            };
        case 'rules':
            return {
                styles: {
                    background: '#EDEDFA',
                    overflow: 'auto'
                }
            };
        default:
            return null;
    }
};

const PopUp2: React.FC<PopUpProps> = ({
    children,
    customClasses,
    onClose,
    open,
    title,
    ...rest
}) => {
    const classes = useStyles();

    const { debitInfo, modalClose, modalTitle, photo, nonAutoTransfer } = rest;

    const additionalStyles = renderStyles(title) || null;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor:
                        !loading || !success || !nonAutoTransfer
                            ? 'rgba(0, 0, 0, 0.9)'
                            : 'none !important'
                }
            }}
        >
            <div className={classes.modal}>
                {(loading || success) && (
                    <div className={classes.overlayContainer}>
                        {loading ? (
                            <LoadingCircle />
                        ) : (
                            <div className={classes.successContainer}>
                                <i className="fas fa-check-circle" />
                                {renderOverlayMessage(title, debitInfo)}
                            </div>
                        )}
                    </div>
                )}
                {modalTitle && modalTitle.length > 0 && (
                    <div className={classes.titleContainer}>
                        <span className={classes.title}>{modalTitle}</span>
                    </div>
                )}
                <div
                    className={classes.modalContent}
                    style={additionalStyles ? additionalStyles.styles : {}}
                >
                    {photo && (
                        <div className={classes.photoContainer}>
                            <img
                                src={photo}
                                alt="profile"
                                className={classes.photo}
                            />
                        </div>
                    )}
                    {additionalStyles && additionalStyles.iconName && (
                        <div
                            className={classes.iconContainer}
                            style={additionalStyles.iconStyle}
                        >
                            <i className={`fas ${additionalStyles.iconName}`} />
                        </div>
                    )}
                    <div
                        className={classes.content}
                        style={
                            customClasses?.content ? customClasses.content : {}
                        }
                    >
                        {cloneElement(children as React.ReactElement, {
                            setLoading,
                            setSuccess
                        })}
                    </div>
                </div>
                {modalClose && (
                    <div className={classes.btnContainer}>
                        <Button className={classes.btn} onClick={onClose}>
                            Close
                        </Button>
                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default PopUp2;
